import * as React from "react"
import Layout from "../components/layout"
import Wrapper from "../components/Resultados/wrapper"

const ClientSideOnlyLazy = React.lazy(() =>
  import('../components/Resultados/wrapper')
)

const IndexPage = () => {
  const isSSR = typeof window === "undefined"
  return(
    <Layout>
      {!isSSR && (
          <React.Suspense fallback={<div />}>
            <ClientSideOnlyLazy />
          </React.Suspense>
        )}
    </Layout>
  )
}

export default IndexPage